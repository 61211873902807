(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

require("./modules/footer");

require("./modules/menu");

require("./modules/redireccion_servicios");

require("./modules/funciones_maquetacion");

},{"./modules/footer":2,"./modules/funciones_maquetacion":3,"./modules/menu":4,"./modules/redireccion_servicios":5}],2:[function(require,module,exports){
"use strict";

$(document).ready(function () {
	$("#btn-subir").click(function (e) {

		$("html,body").stop().animate({ scrollTop: 0 }, 800, 'swing', function () {
			//callback
		});
	});
});

},{}],3:[function(require,module,exports){
'use strict';

//Ready
$(function () {
	$(".blockserios").on('mousedown', function (event) {
		event.preventDefault();

		return false;
	}).on('contextmenu', function (event) {
		event.preventDefault();

		return false;
	});
});

},{}],4:[function(require,module,exports){
'use strict';

$(document).ready(function () {

	$('#menu-icon').click(function (event) {
		$('#menu-movil').toggle();
		setTimeout(function () {
			$('#menu-movil').toggleClass('active');
		}, 100);
	});
	$('.icon-close, #menu-movil a').click(function (event) {
		$('#menu-movil').toggleClass('active');
		setTimeout(function () {
			$('#menu-movil').toggle();
		}, 2000);
	});

	$("#to_up").click(function (event) {
		$('html, body').animate({
			scrollTop: 0
		}, 1000);
	});
});

},{}],5:[function(require,module,exports){
"use strict";

$(document).ready(function () {
	$("div.servicio").click(function (e) {
		var url = $(this).data("url");
		//location.href = "/single/"+id;
		location.href = url;
	});
});

},{}]},{},[1]);
